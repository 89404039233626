import React from "react";
import PageWrapper from '../components/PageWrapper';
//import RegisterForm from "../components/User/RegisterForm";
import PortCongestion from '../components/Enquiry/Components/PortCongestion';
import { Box } from "../components/Layout"
import colors from '../utils/colors';

const Home = () => (
  <PageWrapper>
    <Box bg={colors.primary}>
        <Box
        width={[1, 1, 1]}
        m={['0 0 0 0', '0 0 0 0', '0 auto 0 auto']}
        px={[3, 3, 0]}
        color={colors.secondary}
        >
      {typeof window !== `undefined`?<PortCongestion />:<></>}
      </Box></Box>    
  </PageWrapper>
)

export default Home
